@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&family=Almarai:wght@300;400;700;800&display=swap');


body {
  margin: 0;
  font-family: 'Almarai';
}

code {
  font-family: 'Almarai';
}
