/* General styles - outside media query */

@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&family=Almarai:wght@300;400;700;800&display=swap');


#language-switcher {
  width: 100%;
  height: 8%;
  display: flex;
  gap: 10px;
  padding: 14px;
}

#language-switcher button {
  padding: 4px 8px;
  font-size: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#language-switcher button:hover {
  background-color: #0056b3;
}

[dir="rtl"] #language-switcher {
  left: 20px;
  right: auto;
  direction: ltr;
}

@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}
